import clsx from "clsx";
import React, { FC, ReactNode } from "react";

import { Icon } from "../Icon";
import { SearchBar } from "../SearchBar";

import SearchIcon from "../../icons/search.svg";

import { StyleProps } from "../../interfaces/common";

import { debounce } from "../../helpers/patterns";
import { capitalize } from "../../helpers/stringMethods";

import useKeypress from "../../hooks/UseKeyPress";

import css from "./Modal.module.scss";

type ModalProps = StyleProps & {
  title: string;
  hasSearch?: boolean;
  open: boolean;
  children: ReactNode;
  onClose?: () => void;
  onSearch?: (value: string) => void;
};

export const Modal: FC<ModalProps> = (props) => {
  const {
    title,
    hasSearch = false,
    open = false,
    children,
    className,
    style,
    onClose,
    onSearch,
  } = props;

  const processSearch = debounce((text: string) => onSearch?.(text));

  const handleModal = (event: string) => () => {
    // console.log(event);
    // Can be used to run functions on specific events
    onClose?.();
  };

  useKeypress("Escape", handleModal("Escape"));

  return (
    <>
      {open && (
        <div
          className={css.background}
          onClick={handleModal("backdropClick")}
        />
      )}
      <div className={css.center}>
        <div className={clsx(css.modal, className)} style={style}>
          <div className={css.header}>
            <span>{capitalize(title)}</span>
            {hasSearch && (
              <SearchBar
                onChange={processSearch}
                placeholder={"Comienza a escribir..."}
                rightIcon={<img src={SearchIcon} alt="search-icon" />}
              />
            )}
            <Icon type="cross" onClick={handleModal("backdropClick")} />
          </div>
          <div className={css.content}>{children}</div>
        </div>
      </div>
    </>
  );
};
