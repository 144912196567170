import React, { useState } from "react";
import { Button } from "./components/Button";
import { OKRModal } from "./components/OKRsTemplateModal";
import { OKRTemplates } from "./components/OKRsTemplateModal/OKRModal";

import "./styles/global.scss";

export default function App() {
  const departments = [
    "Producto",
    "Marketing",
    "Ingeniería",
    "Producción",
    "Diseño",
    "Ventas",
    "Cultura",
  ];

  const tags = [
    "MVP",
    "NPS",
    "PMF",
    "Testing",
    "Frontend",
    "Backend",
    "UI",
    "UX",
    "CX",
  ];

  const cards: OKRTemplates = {};

  departments.forEach((department) => {
    cards[department] = Array.from(
      { length: Math.floor(Math.random() * 20) + 1 },
      () => ({
        area: `Area - ${department}`,
        title: `Title - ${department}`,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum adipiscing feugiat mollis at.",
        tags: tags.sort(() => Math.random() - Math.random()).slice(0, 3),
        about:
          "About Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum adipiscing feugiat mollis at.",
        objective: `Build an amazing ${department}`,
        keyResults: [
          {
            title: `Key Result 1 - ${department}`,
            kpis: ["KPI 1-1", "KPI 2-1", "KPI 3-1"],
          },
          {
            title: `Key Result 2 - ${department}`,
            kpis: ["KPI 1-2", "KPI 2-2", "KPI 3-2"],
          },
        ],
      })
    );
  });

  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <div>
      <h1>OKR Template Modal</h1>
      <Button
        variant="secondary"
        text="Open Modal"
        onClick={() => setOpenModal(true)}
      />
      {isOpenModal && (
        <OKRModal
          title="Plantilla de Objetivos"
          templates={cards}
          open={isOpenModal}
          onClose={() => setOpenModal(false)}
        >
          <p>Hola Mundo</p>
        </OKRModal>
      )}
    </div>
  );
}
