import clsx from "clsx";
import React, { FC } from "react";

import { StyleProps } from "../../interfaces/common";

import css from "./Divider.module.scss";

type DividerProps = StyleProps;

export const Divider: FC<DividerProps> = (props) => {
  const { className, style } = props;

  return <div className={clsx(css.divider, className)} style={style} />;
};
