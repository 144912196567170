import clsx from "clsx";
import React, { FC } from "react";

import { StyleProps } from "../../interfaces/common";

import css from "./Pill.module.scss";

type PillProps = StyleProps & {
  text: string;
  color?: "default" | "green" | "blue" | "red" | "yellow";
};

export const Pill: FC<PillProps> = (props) => {
  const { text, color = "default", className, style } = props;

  return (
    <div data-color={color} className={clsx(css.pill, className)} style={style}>
      <span>{text}</span>
    </div>
  );
};
