import clsx from "clsx";
import React, { FC } from "react";

import { Button } from "../Button";
import { Pill } from "../Pill";
import { OKRDiagram } from "../OKRDiagram";

import { StyleProps } from "../../interfaces/common";
import { Template } from "../OKRsTemplateModal/OKRModal";

import css from "./Preview.module.scss";
import { Divider } from "../Divider";

type PreviewProps = StyleProps & {
  template: Template;
  onClick: () => void;
};

export const Preview: FC<PreviewProps> = (props) => {
  const {
    template: { title, description, tags = [], objective, keyResults },
  } = props;
  const { onClick, className, style } = props;

  return (
    <div className={clsx(css.preview, className)} style={style}>
      <div className={css.heading}>
        <span className={css.title}>{title}</span>
        <Button
          variant="primary"
          text="Usar como plantilla"
          onClick={typeof onClick === "function" ? onClick : undefined}
        />
      </div>
      <p>{description}</p>
      <OKRDiagram objective={objective} keyResults={keyResults} />
      <div className={css.tags}>
        {tags?.map((label, index) => (
          <Pill key={index} text={label} />
        ))}
      </div>
      <span className={css.title}>Acerca de la plantilla</span>
      <Divider />
      <p>{description}</p>
    </div>
  );
};
