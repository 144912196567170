import clsx from "clsx";
import React, { FC } from "react";

import { StyleProps } from "../../interfaces/common";

import css from "./PillButton.module.scss";

type PillButtonProps = StyleProps & {
  text: string | number;
  selected?: boolean;
  notHover?: boolean;
  onClick?: (value: string | number) => void;
};

export const PillButton: FC<PillButtonProps> = (props) => {
  const {
    text,
    selected = false,
    notHover = false,
    onClick,
    className,
    style,
  } = props;

  return (
    <button
      className={clsx(css.button, className)}
      style={style}
      data-active={(selected && !notHover) || undefined}
      data-not-hover={notHover || undefined}
      onClick={() => onClick?.(text)}
    >
      <span>{text}</span>
    </button>
  );
};
