import clsx from "clsx";
import React, { FC } from "react";

import { Pill } from "../Pill";

import { StyleProps } from "../../interfaces/common";

import css from "./Diagram.module.scss";

type DiagramProps = StyleProps & {
  objective: string;
  keyResults: { title: string; kpis: string[] }[];
};

export const Diagram: FC<DiagramProps> = (props) => {
  const { objective, keyResults, className, style } = props;
  return (
    <div className={clsx(css.diagram, className)} style={style}>
      <div className={css.arrow}>
        <Pill text={"OBJ"} color="green" />
        <span>{objective}</span>
      </div>
      {keyResults.map(({ title, kpis }, index) => (
        <div key={index} className={css.level}>
          <div className={css.arrow}>
            <Pill text={"RC"} color="blue" />
            <span>{title}</span>
          </div>
          <div className={css.level}>
            {kpis.map((kpi, index) => (
              <div key={index} className={css.arrow}>
                <Pill text={"CK"} />
                <span>{kpi}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
