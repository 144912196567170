import clsx from "clsx";
import { FC } from "react";

import { StyleProps } from "../../interfaces/common";

import css from "./Card.module.scss";

type CardProps = StyleProps & {
  title: string;
  description?: string;
  active?: boolean;
  onClick?: () => void;
};

export const Card: FC<CardProps> = (props) => {
  const {
    title,
    description,
    active = false,
    onClick,
    className,
    style,
  } = props;
  return (
    <div
      data-active={active || undefined}
      className={clsx(css.card, className)}
      style={style}
      onClick={typeof onClick === "function" ? onClick : undefined}
    >
      <span>{title}</span>
      <p>{description}</p>
    </div>
  );
};
