import { FC, useState, useEffect, useMemo } from "react";
import clsx from "clsx";

import css from "./OKRModal.module.scss";

import { PillButton } from "../PillButton";
import { Card } from "../Card";

import { StyleProps } from "../../interfaces/common";

import { OKRPreview } from "../OKRPreview";
import { Modal } from "../Modal";

type OKR = {
  title: string;
  kpis: string[];
};

export type Template = {
  title: string;
  description: string;
  tags?: string[];
  about: string;
  objective: string;
  keyResults: OKR[];
  [key: string]: unknown;
};

export type OKRTemplates = Record<string, Template[]>;

type Props = StyleProps & {
  title: string;
  templates: OKRTemplates;
  filterBy?: keyof Omit<Template, "tags">;
  open: boolean;
  onClose?: () => void;
};

export const OKRModal: FC<Props> = (props: Props) => {
  const {
    title,
    templates = {},
    filterBy,
    open,
    onClose,
    className,
    style,
  } = props;

  const departments = useMemo(() => Object.keys(templates).sort(), [templates]);

  const allTemplates = useMemo(
    () =>
      Object.keys(templates)
        .map((department) => templates[department])
        .flat(),
    [templates]
  );

  const [currentKeyValue, setCurrentKeyValue] = useState<string | number>(
    "Todos"
  );
  const [keys, setKeys] = useState<(string | number)[]>(departments);
  const [selectedTemplates, setSelectedTemplates] =
    useState<Template[]>(allTemplates);
  const [currentTemplate, setCurrentTemplate] = useState<Template>();

  useEffect(() => {
    if (!filterBy) {
      return;
    }

    const filteredKeys = allTemplates.map((template) => template[filterBy]) as (
      | string
      | number
    )[];

    // if (!isAllType(filteredKeys, "string")) return;
    // Validate if some value on array is null - undefined

    setKeys([...new Set(filteredKeys)].sort());
  }, [allTemplates, filterBy]);

  const onCloseModal = () => {
    setCurrentTemplate(undefined);
    onClose?.();
  };

  const useTemplate = () => {
    console.log(currentTemplate);
    onCloseModal();
  };

  const filterByKey = (selectedKey: string | number) => {
    /* Al hacer click sobre un pill ya seleccionado se manda a "Todos"
      if (selectedKey === currentKeyValue) {
        setCurrentKeyValue("Todos");
        setSelectedTemplates(templates);
        return;
      }
    */

    setCurrentKeyValue(selectedKey);

    if (selectedKey === "Todos") {
      setSelectedTemplates(allTemplates);
      return;
    }

    if (!filterBy) {
      setSelectedTemplates(templates[selectedKey]);
    }

    if (filterBy) {
      const currentTemplates = allTemplates.filter(
        (template) => template[filterBy] === selectedKey
      );
      setSelectedTemplates(currentTemplates);
    }
  };

  const filterBySearchBar = (text: string) => {
    if (!text) {
      filterByKey(currentKeyValue);
      return;
    }

    const currentSearch = allTemplates.filter(
      ({ title, tags }) =>
        title.toLowerCase().includes(text.toLowerCase()) ||
        tags?.join(" ").toLowerCase().includes(text.toLowerCase())
    );
    setSelectedTemplates(currentSearch);
  };

  const isSelected = (key: string | number) => currentKeyValue === key;

  return (
    <Modal
      title={title}
      open={open}
      onClose={onCloseModal}
      hasSearch
      onSearch={filterBySearchBar}
      className={className}
      style={style}
    >
      <div className={css.filters}>
        <div className={css.keyFilter}>
          {["Todos", ...keys].map((label, index) => (
            <PillButton
              key={index}
              text={label}
              selected={isSelected(label)}
              onClick={filterByKey}
            />
          ))}
        </div>
        {filterBy && <div>{/* Can be add custom filters */}</div>}
      </div>
      <div
        className={clsx(css.content, {
          [css.onPreview]: currentTemplate,
        })}
      >
        <div className={css.templateCards}>
          <span>Todas las plantillas</span>
          <div className={css.cards}>
            {selectedTemplates.length ? (
              selectedTemplates.map((template, index) => (
                <Card
                  key={index}
                  title={template.title}
                  description={template.description}
                  onClick={() => setCurrentTemplate(template)}
                  active={currentTemplate === template}
                />
              ))
            ) : (
              <p>No se encontraron coincidencias</p>
            )}
          </div>
        </div>
        {currentTemplate && (
          <OKRPreview template={currentTemplate} onClick={useTemplate} />
        )}
      </div>
    </Modal>
  );
};
