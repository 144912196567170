import { FC } from "react";
import css from "./Icon.module.scss";

const IconList = {
  cross: "crossIcon JSX - SVG",
};

type IconListType = keyof typeof IconList;

interface Props {
  type: IconListType;
  // fill?: string;
  // stroke?: string;
  // iconsize?: number;
  onClick: () => void;
}

const Icon: FC<Props> = (props) => {
  // const { type } = props;
  const { onClick } = props;
  // const CurrentIcon = IconList[type] || IconList.cross;
  return (
    <div className={css.icon}>
      <div className={css.cross} onClick={onClick} />
    </div>
  );
};

export default Icon;
