import { FC, ReactNode } from "react";
import css from "./SearchBar.module.scss";

interface Props {
  placeholder: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onChange: (value: string) => void;
}

export const SearchBar: FC<Props> = (props) => {
  const { placeholder, leftIcon, rightIcon } = props;
  const { onChange } = props;
  return (
    <div
      className={css.searchBar}
      data-lefticon={!!leftIcon || undefined}
      data-righticon={!!rightIcon || undefined}
    >
      {leftIcon && <span className={css.icon}>{leftIcon}</span>}
      <input
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
      />
      {rightIcon && <span className={css.icon}>{rightIcon}</span>}
    </div>
  );
};
