import React, { ReactNode, forwardRef } from "react";
import clsx from "clsx";

import { StyleProps } from "../../interfaces/common";

import css from "./Button.module.scss";

type ButtonProps = StyleProps & {
  variant: "primary" | "secondary" | "tertiary";
  small?: boolean;
  text: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  href?: string;
  newTab?: boolean;
  disabled?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  color?: "default" | "danger";
};

export const Button = forwardRef<any, ButtonProps>((props, ref) => {
  const {
    variant = "primary",
    color = "default",
    small = false,
    text,
    type,
    onClick,
    href,
    newTab = false,
    disabled = false,
    leftIcon,
    rightIcon,
    className,
    style
  } = props;

  const isButton = !href;
  const Component = isButton ? "button" : "a";
  const isNewTab = !isButton && newTab;

  return (
    <Component
      ref={ref}
      data-variant={variant}
      data-color={color}
      data-small={small || undefined}
      data-lefticon={!!leftIcon || undefined}
      data-righticon={!!rightIcon || undefined}
      className={clsx(css.button, className)}
      style={style}
      disabled={(isButton && disabled) || undefined}
      type={isButton && !!type ? type : undefined}
      target={isNewTab ? "_blank" : undefined}
      rel={isNewTab ? "noopener noreferrer" : undefined}
      onClick={typeof onClick === "function" ? onClick : undefined}
    >
      {leftIcon && <span className={css.icon}>{leftIcon}</span>}

      <span>{text}</span>

      {rightIcon && <span className={css.icon}>{rightIcon}</span>}
    </Component>
  );
});
Button.displayName = "Button";
